import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { handleLoadWindow, scrollTo, scrollTop } from '../../helpers/common/screen';
import { TrackList } from '../TrackList/TrackList';
import { Image } from '../common/Image';
import { Link } from '../common/Link';

import './AlbumList.css';

export const AlbumList = ({
  albumList,
}) => {
  const { id } = useParams();

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    handleLoadWindow(() => {
      scrollTo(`.album-${id}`, '.albums');
    });
  }, [id]);

  return (
    <div className="albums">
      {albumList.map(album => {
        const isCurrentAlbum = id === album.id;

        return (
          <div
            key={album.id}
            className={classNames(
              'album',
              `album-${album.id}`,
              isCurrentAlbum && 'current',
              'inline-block',
            )}
          >
            <Link
              to={isCurrentAlbum ? '/albums' : `/albums/${album.id}`}
              className="block"
            >
              <Image
                className="cover block"
                src={album.imgPath}
                alt={album.title}
              />
              <p className="description">{album.title}</p>
            </Link>
            {isCurrentAlbum && (
              <TrackList
                trackList={album.tracks}
                showLyrics={false}
                autoScroll={false}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

AlbumList.propTypes = {
  albumList: PropTypes.array.isRequired,
};
