import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import TurndownService from 'turndown';

import { useDataContext } from '../../context/data-context';
import { getAudioElement, loadAudio } from '../../helpers/common/audio';
import { handleLoadWindow, scrollTo } from '../../helpers/common/screen';
import { File } from '../common/File';
import { Icon } from '../common/Icon';
import { Image } from '../common/Image';

import './TrackList.css';

export const TrackList = ({
  trackList,
  showLyrics = true,
  autoScroll = true,
}) => {
  const { currentTrack, setCurrentTrack } = useDataContext();
  const [lyrics, setLyrics] = useState([]);

  useEffect(() => {
    if (autoScroll) {
      handleLoadWindow(() => {
        scrollTo('.track.current', '.tracks');
      });
    }
  }, [autoScroll]);

  const handleClickTrack = useCallback(track => {
    const audio = getAudioElement();

    if (currentTrack.path === track.path) {
      audio[audio.paused ? 'play' : 'pause']();
    } else {
      loadAudio(track, true);
      setCurrentTrack(track);
    }
  }, [currentTrack, setCurrentTrack]);

  const handleClickLyricsToggle = useCallback((e, track) => {
    e.preventDefault();
    e.stopPropagation();
    if (lyrics.includes(track.path)) {
      setLyrics(lyrics.filter(item => item !== track.path));
    } else {
      setLyrics([
        ...lyrics,
        track.path,
      ]);
    }
  }, [lyrics]);

  const handleClickLyrics = useCallback((e, track) => {
    e.preventDefault();
    e.stopPropagation();
    navigator?.clipboard?.writeText(new TurndownService().turndown(track.lyrics));
  }, []);

  return (
    <div className="tracks">
      {trackList.map((track, index) => (
        <File
          key={track.path}
          className={classNames(
            'track',
            currentTrack.path === track.path && 'current',
            'block',
          )}
          onClick={() => {
            handleClickTrack(track);
          }}
          path={track.path}
        >
          <div className="wrapper">
            <div className="info">
              <span className="index">
                {index + 1}
              </span>
              <Image
                src={track.imgPath}
                alt={track.tag.album}
              />
              <span className="title">
                {track.tag.title}
              </span>
            </div>
            {showLyrics && track.lyrics && (
              <div
                className="icon"
                onClick={e => {
                  handleClickLyricsToggle(e, track);
                }}
              >
                <Icon id="file-text" />
              </div>
            )}
          </div>
          {lyrics.includes(track.path) && (
            <div
              className="lyrics"
              dangerouslySetInnerHTML={{ __html: track.lyrics }}
              onClick={e => {
                handleClickLyrics(e, track);
              }}
            />
          )}
        </File>
      ))}
    </div>
  );
};

TrackList.propTypes = {
  image: PropTypes.object,
  trackList: PropTypes.array.isRequired,
  showLyrics: PropTypes.bool,
  autoScroll: PropTypes.bool,
};
