import classNames from 'classnames';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getRandom } from '../../helpers/common/math';
import { handleLoadWindow, scrollTo, scrollTop } from '../../helpers/common/screen';
import { getConfig } from '../../helpers/data';
import { Image } from '../common/Image';
import { Link } from '../common/Link';

import './EventList.css';

const getAltImage = () => {
  const { altImageList } = getConfig();

  return altImageList[getRandom(0, altImageList.length - 1)];
};

export const EventList = ({ eventList }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    handleLoadWindow(() => {
      scrollTo(`.event-${id}`, '.events');
    });
  }, [id]);

  const handleClickEvent = event => {
    navigate(`/events/${event.id}`);
  };

  return (
    <div className="events">
      {eventList.map(event => {
        const isCurrentEvent = id === event.id;

        return (
          <div
            key={event.id}
            className={classNames(
              'event',
              `event-${event.id}`,
              isCurrentEvent && 'current',
              'inline-block',
            )}
          >
            <Link
              to={event.url}
              className="block"
              onClick={() => {
                handleClickEvent(event);
              }}
            >
              <Image
                className={classNames([
                  'block',
                  { fallback: !event.imgPath },
                  { sepia: !(event.imgPath || getRandom(0, 3)) },
                  { saturate: !(event.imgPath || getRandom(0, 3)) },
                  { 'hue-rotate': !(event.imgPath || getRandom(0, 3)) },
                  { invert: !(event.imgPath || getRandom(0, 3)) },
                  { rotateY: !(event.imgPath || getRandom(0, 3)) },
                  { mirror: !event.imgPath && !!getRandom(0, 1) },
                ])}
                src={event.imgPath || getAltImage()}
                alt={event.title}
              />
              <p className="description">
                {event.title}
              </p>
              <p className="description">
                {format(new Date(event.date), 'dd.MM.yyyy')}
              </p>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

EventList.propTypes = {
  eventList: PropTypes.array.isRequired,
};
